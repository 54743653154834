



























import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import CShareButtons from '~/components/shared/configurable/social/CShareButtons.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CShareButtons },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      modalVisible: false,
      icons: {
        shareAlt: faShareAlt
      }
    }
  },
  methods: {
    handleShareButtonClick() {
      this.$analytics.recordEvent({
        namespace: 'n_classifieds_search_share_button',
        action: 'click'
      })
      this.modalVisible = true
    }
  }
})
